<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="closeDialog" @closed="closeDialog" width="30%" @open="addOpen" :close-on-click-modal="false">
    <el-form :model="formData" :rules="replyFormRules" ref="replyFormRef" label-width="80px">
      <el-form-item label="回复内容" prop="reply">
        <el-input v-model="formData.reply" type="textarea" :rows="2"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="addConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'comment_reply',
  props: [
    'visible', 'id'
  ],
  data () {
    return {
      title: '评论回复',
      formData: {
        reply: ''
      },
      replyFormRules: {
        reply: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.replyFormRef.resetFields()
      this.$emit('closeDialog')
    },
    addOpen () {
      this.getInfo(this.id)
    },
    addConfirm () {
      this.$refs.replyFormRef.validate(valid => {
        if (!valid) return
        // 验证通过
        this.$emit('replyCommit', this.id, this.formData)
      })
    },
    async getInfo (id) {
      const res = await this.$http.get('/admin/comment/info/' + id)
      if (res.code === 0) {
        this.formData = {
          reply: res.data.reply
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
