<template>
  <div class="ue">
    <vue-ueditor-wrap v-model="data" :config="myConfig"></vue-ueditor-wrap>
  </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
  name: 'test_ueditor',
  components: {
    'vue-ueditor-wrap': VueUeditorWrap
  },
  data () {
    return {
      data: 'dome',
      myConfig: {
        autoHeightEnabled: false,
        serverUrl: '/proxy/upload',
        imageAllowFiles: ['.png', '.jpg', '.jpeg', ',gif'],
        imageMaxSize: 20480000,
        fileAllowFiles: ['.pdf', 'doc', 'docx', 'zip', 'rar'],
        initialFrameHeight: 320,
        initialFrameWidth: '1000',
        UEDITOR_HOME_URL: '/ueditor/'
      }
    }
  }
}
</script>

<style scoped>
</style>
