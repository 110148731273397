import { render, staticRenderFns } from "./commentUpdate.vue?vue&type=template&id=38129689&scoped=true&"
import script from "./commentUpdate.vue?vue&type=script&lang=js&"
export * from "./commentUpdate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38129689",
  null
  
)

export default component.exports