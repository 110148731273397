<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb path='内容管理' subPath='内容列表'></bread-crumb>
    <!-- 主体列表 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-dropdown>
            <el-button type="primary">批量操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item><span @click="showUpdateCategoryDialog">修改专辑</span></el-dropdown-item>
              <el-dropdown-item><span @click="showUpdateCommentDialog">修改评论</span></el-dropdown-item>
              <el-dropdown-item><span @click="multiDeletePost">删除</span></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="showAddDialog">添加内容</el-button>
        </el-col>
        <el-col :span="4">
          <el-select v-model="param.category_id" placeholder="请选择专辑" filterable>
            <el-option label="请选择专辑" :value="0"></el-option>
            <el-option v-for="item in categoryList" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-input placeholder="请输入标题" v-model="param.keywords" clearable @clear="search">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </el-card>
   <el-table :data="contentList" @selection-change="handleSelectionChange" stripe border :cell-style="{ textAlign: 'center' }">
     <el-table-column type="selection" width="40" align="center"></el-table-column>
      <el-table-column prop="id" label="ID" align="center" width="60px"></el-table-column>
      <el-table-column prop="pic" label="图片" align="center">
        <template slot-scope="scope">
          <el-image v-if="scope.row.pic!=''" :src="scope.row.pic+'?imageView2/2/w/75'"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="二维码" align="center" width="180px">
        <template slot-scope="scope">
          <vue-qrcode :value="scope.row.url" :options="{width:150}"></vue-qrcode>
        </template>
      </el-table-column>
      <el-table-column label="标题" align="center">
        <template slot-scope="scope">
          <a :href="scope.row.url" target="_blank">{{scope.row.title}}</a>
        </template>
      </el-table-column>
      <el-table-column prop="category_title" label="专辑" align="center"></el-table-column>
      <el-table-column label="是否评论" align="center">
        <template slot-scope="scope">
          {{scope.row.can_comment | getCanComment}}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="source" label="来源" align="center"></el-table-column> -->
      <el-table-column prop="hits" label="点击量" align="center"></el-table-column>
      <el-table-column label="链接地址" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.is_valid">有效</span>
          <span v-else>无效</span>
        </template>
      </el-table-column>
      <el-table-column label="发布时间" align="center">
        <template slot-scope="scope">
          {{scope.row.publish_start | dateTimeFormat}}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template slot-scope="scope">
          {{scope.row.create_time | dateTimeFormat}}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200px">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteRow(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
   <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="param.page"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="param.count"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
   <content-add :visible="addDialogAvaible"
      :id="currentId"
      :categoryList="categoryList"
      @add="addContent"
      @update="updateContent"
      @close="closeAddDialog">
    </content-add>
    <category-update :visible="updateCategoryAvaible"
       :categoryList="categoryList"
       @update="multiUpdateCategoryPost"
       @close="closeUpdateCategoryDialog">
     </category-update>
     <comment-update :visible="updateCommentAvaible"
        @update="multiUpdateCommentPost"
        @close="closeUpdateCommentDialog">
      </comment-update>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
import ContentAdd from './ContentAdd.vue'
import CategoryUpdate from './categoryUpdate.vue'
import CommentUpdate from './commentUpdate.vue'
import VueQrcode from '@chenfengyuan/vue-qrcode'
export default {
  name: 'content_list',
  components: {
    'bread-crumb': BreadCrumb,
    'content-add': ContentAdd,
    'category-update': CategoryUpdate,
    'comment-update': CommentUpdate,
    'vue-qrcode': VueQrcode
  },
  mounted () {
    if (this.$route.query.param !== undefined) {
      this.param = JSON.parse(this.$route.query.param)
    }
    this.getCategoryList()
    this.getContentList()
  },
  data () {
    return {
      categoryList: [],
      contentList: [],
      currentId: 0,
      total: 0,
      pageTotal: 1,
      param: {
        category_id: 0,
        keywords: '',
        page: 1,
        count: 10
      },
      addDialogAvaible: false,
      idList: [],
      updateCategoryAvaible: false,
      updateCommentAvaible: false
    }
  },
  filters: {
    getCanComment (commentId) {
      if (commentId === 1) {
        return '开启'
      } else if (commentId === 2) {
        return '开启并通过'
      } else {
        return '关闭'
      }
    }
  },
  methods: {
    handleSelectionChange (val) {
      this.idList = []
      val.forEach(row => {
        this.idList.push(row.id)
      })
    },
    async getCategoryList () {
      const res = await this.$http.get('/admin/category/select_list')
      if (res.code === 0) {
        this.categoryList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async getContentList () {
      const res = await this.$http.get('/admin/content/list', this.param)
      if (res.code === 0) {
        this.contentList = res.data.list
        this.total = res.data.total
        this.pageTotal = res.data.total_page
      } else {
        this.$message.error(res.msg)
      }
    },
    search () {
      this.param.page = 1
      this.getContentList()
    },
    handleSizeChange (size) {
      this.param.count = size
      this.getContentList()
    },
    handleCurrentChange (page) {
      this.param.page = page
      this.getContentList()
    },
    showAddDialog () {
      // this.addDialogAvaible = true
      this.$router.push('/content/add?id=' + this.currentId + '&param=' + JSON.stringify(this.param))
    },
    closeAddDialog () {
      this.addDialogAvaible = false
      this.currentId = 0
    },
    showEditDialog (id) {
      this.currentId = id
      this.showAddDialog()
    },
    deleteRow (id) {
      this.$confirm('是否真的要删除此记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.post('/admin/content/delete/' + id)
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.getContentList()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {})
    },
    multiDeletePost () {
      if (this.idList.length === 0) {
        return
      }
      this.$confirm('是否真的要删除这些内容?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.post('/admin/content/multi/delete', { ids: this.idList })
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.getContentList()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {})
    },
    async addContent (data) {
      const res = await this.$http.post('/admin/content/add', data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getContentList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async updateContent (id, data) {
      const res = await this.$http.post('/admin/content/update/' + id, data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getContentList()
      } else {
        this.$message.error(res.msg)
      }
    },
    showUpdateCategoryDialog () {
      this.updateCategoryAvaible = true
    },
    closeUpdateCategoryDialog () {
      this.updateCategoryAvaible = false
    },
    async multiUpdateCategoryPost (categoryId) {
      if (this.idList.length === 0) {
        this.closeUpdateCategoryDialog()
        return
      }
      const res = await this.$http.post('/admin/content/multi/change/category', {
        ids: this.idList,
        category_id: categoryId
      })
      if (res.code === 0) {
        this.closeUpdateCategoryDialog()
        this.getContentList()
      } else {
        this.$message.error(res.msg)
      }
    },
    showUpdateCommentDialog () {
      this.updateCommentAvaible = true
    },
    closeUpdateCommentDialog () {
      this.updateCommentAvaible = false
    },
    async multiUpdateCommentPost (commentStatus) {
      if (this.idList.length === 0) {
        this.closeUpdateCommentDialog()
        return
      }
      const res = await this.$http.post('/admin/content/multi/change/comment', {
        ids: this.idList,
        comment_status: commentStatus
      })
      if (res.code === 0) {
        this.closeUpdateCommentDialog()
        this.getContentList()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.list_img {
  width: 50px;
}
</style>
