<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="closeDialog" @closed="closeDialog" width="30%" @open="addOpen" :close-on-click-modal="false">
    <el-form :model="formData" :rules="addFormRules" ref="addFormRef" label-width="80px">
      <el-form-item label="名称" prop="title">
        <el-input v-model="formData.title"></el-input>
      </el-form-item>
      <el-form-item label="合集" prop="group_id">
        <el-select v-model="formData.group_id" placeholder="请选择合集" filterable>
          <el-option label="请选择合集" :value="0"></el-option>
          <el-option v-for="item in groupList" :key="item.id" :label="item.title" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片" prop="pic">
        <template>
          <el-upload
            class="avatar-uploader"
            action="/proxy/upload_img"
            name="img"
            :show-file-list="false"
            :on-success="handleSuccess">
            <img v-if="picUrl" :src="picUrl+'?imageView2/0/w/50'" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </template>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="addConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'category_add',
  props: [
    'visible', 'id', 'groupList'
  ],
  data () {
    return {
      title: '添加专辑',
      formData: {
        title: '',
        group_id: 0,
        pic: ''
      },
      picUrl: '',
      addFormRules: {
        title: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.addFormRef.resetFields()
      this.picUrl = ''
      this.$emit('closeDialog')
    },
    addConfirm () {
      this.$refs.addFormRef.validate(valid => {
        if (!valid) return
        // 验证通过
        if (this.id === 0) {
          this.$emit('add', this.formData)
        } else {
          this.$emit('update', this.id, this.formData)
        }
      })
    },
    addOpen () {
      // 如果id>0,则是编辑信息
      if (this.id > 0) {
        this.title = '编辑专辑'
        this.getInfo(this.id)
      } else {
        this.title = '添加专辑'
      }
    },
    async getInfo (id) {
      const res = await this.$http.get('/admin/category/info/' + id)
      if (res.code === 0) {
        this.formData = {
          title: res.data.title,
          group_id: res.data.group_id,
          pic: res.data.pic
        }
        this.picUrl = res.data.pic_url
      }
    },
    handleSuccess (resp, file, fileList) {
      if (resp.code === 0) {
        this.picUrl = resp.data.url
        this.formData.pic = resp.data.path
      } else {
        this.$message.error(resp.msg)
      }
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.avatar {
  width: 50px;
  height: 50px;
  display: block;
}
</style>
