<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="closeDialog" @closed="closeDialog" width="80%" @open="addOpen" :close-on-click-modal="false">
    <el-form :model="formData" :rules="formRules" ref="formRef" label-width="100px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="formData.title"></el-input>
      </el-form-item>
      <el-form-item label="专辑" prop="category_id">
        <el-select v-model="formData.category_id" placeholder="请选择专辑" filterable>
          <el-option label="请选择专辑" :value="0"></el-option>
          <el-option v-for="item in categoryList" :key="item.id" :label="item.title" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片" prop="pic">
        <template>
          <el-upload
            class="avatar-uploader"
            action="/proxy/upload_img"
            name="img"
            :show-file-list="false"
            :on-success="handlePicSuccess">
            <img v-if="picUrl" :src="picUrl+'?imageView2/0/w/50'" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </template>
      </el-form-item>
      <el-form-item label="来源" prop="source">
        <el-input v-model="formData.source"></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="body" style="width: 98%;">
        <my-q-editor :value="formData.body" @editorContent="editorContent"></my-q-editor>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="addConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import MyQEditor from '../MyQEditor.vue'
export default {
  name: 'content_add',
  props: [
    'visible', 'id', 'categoryList'
  ],
  components: {
    'my-q-editor': MyQEditor
  },
  data () {
    return {
      mediaFileList: [],
      title: '添加内容',
      formData: {
        title: '',
        category_id: 0,
        pic: '',
        source: '',
        body: ''
      },
      picUrl: '',
      formRules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        category_id: [
          { required: true, message: '分类不能为空', trigger: 'blur' }
        ],
        pic: [
          { required: true, message: '图片不能为空', trigger: 'blur' }
        ],
        body: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.formRef.resetFields()
      this.mediaFileList = []
      this.picUrl = ''
      this.$emit('close')
    },
    addConfirm () {
      this.$refs.formRef.validate(valid => {
        if (!valid) return
        // 验证通过
        if (this.id === 0) {
          this.$emit('add', this.formData)
        } else {
          this.$emit('update', this.id, this.formData)
        }
      })
    },
    addOpen () {
      // 如果id>0,则是编辑信息
      if (this.id > 0) {
        this.title = '编辑音视频'
        this.getInfo(this.id)
      } else {
        this.formData.published_time = new Date().getTime()
      }
    },
    async getInfo (id) {
      const res = await this.$http.get('/admin/content/info/' + id)
      if (res.code === 0) {
        this.formData = {
          title: res.data.title,
          category_id: res.data.category_id,
          pic: res.data.pic,
          source: res.data.source,
          body: res.data.body
        }
        this.picUrl = res.data.pic_url
        this.mediaFileList = []
      }
    },
    editorContent (content) {
      this.formData.body = content
    },
    handlePicSuccess (resp, file, fileList) {
      if (resp.code === 0) {
        this.picUrl = resp.data.url
        this.formData.pic = resp.data.path
      } else {
        this.$message.error(resp.msg)
      }
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.avatar {
  width: 50px;
  height: 50px;
  display: block;
}
</style>
