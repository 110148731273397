<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="closeDialog" @closed="closeDialog" width="25%" :close-on-click-modal="false">
    <el-form :model="formData" ref="formRef" label-width="80px">
      <el-form-item label="是否评论" prop="comment_status">
        <el-select v-model="formData.comment_status" placeholder="请选择评论状态" filterable>
          <el-option label="关闭" :value="0"></el-option>
          <el-option label="开启" :value="1"></el-option>
          <el-option label="开启并默认通过" :value="2"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="updateConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'comment_update',
  props: [
    'visible'
  ],
  data () {
    return {
      title: '批量更新评论属性',
      formData: {
        comment_status: 0
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.formRef.resetFields()
      this.$emit('close')
    },
    updateConfirm () {
      this.$refs.formRef.validate(valid => {
        if (!valid) return
        // 验证通过
        this.$emit('update', this.formData.comment_status)
      })
    }
  }
}
</script>

<style scoped>
</style>
