<template>
  <div style="width: 100%;">
    <el-upload :show-upload-list="false"
      :on-success="handleImageQuillSuccess"
      :format="['jpg','jpeg','png','gif']"
      :max-size="2048" multiple
      action="/proxy/upload_img"
      name="img"
      style="display: none;">
      <el-button id="quill_upload_image" icon="ios-cloud-upload-outline" ></el-button>
    </el-upload>
    <el-upload :show-upload-list="false"
      :on-success="handleMediaQuillSuccess"
      action="/proxy/upload_media"
      name="media"
      style="display: none;">
      <el-button id="quill_upload_media"></el-button>
    </el-upload>
   <quill-editor ref="quillEditorRef" v-model="content" @change="getContent" :options="editorOption" />
  </div>
</template>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)
// 设置字体大小
const fontSizeStyle = Quill.import('attributors/style/size') // 引入这个后会把样式写在style上
fontSizeStyle.whitelist = ['12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px']
Quill.register(fontSizeStyle, true)
// 设置字体样式
const Font = Quill.import('attributors/style/font') // 引入这个后会把样式写在style上
const fonts = [
  'SimSun',
  'SimHei',
  'Microsoft-YaHei',
  'KaiTi',
  'FangSong',
  'Arial',
  'Times New Roman',
  'Sans-Serif'
]
Font.whitelist = fonts // 将字体加入到白名单
Quill.register(Font, true)
// 工具栏
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  [{ align: [] }], // 对齐方式-----[{ align: [] }]
  [{ size: fontSizeStyle.whitelist }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
  [{ font: fonts }], // 字体种类-----[{ font: [] }]
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ direction: 'rtl' }], // 文本方向-----[{'direction': 'rtl'}]
  [{ indent: '-1' }, { indent: '+1' }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ script: 'sub' }, { script: 'super' }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  ['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
  ['clean'], // 清除文本格式-----['clean']
  ['link', 'image', 'video'] // 链接、图片、视频-----['link', 'image', 'video']
]
export default {
  name: 'VueQuillEditor',
  components: {
    quillEditor
  },
  props: [
    'value'
  ],
  data () {
    return {
      content: this.value,
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image (value) {
                if (value) {
                  document.querySelector('#quill_upload_image').click()
                }
              },
              video (value) {
                if (value) {
                  document.querySelector('#quill_upload_media').click()
                }
              }
            }
          },
          imageDrop: true,
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar']
          }
        }
      }
    }
  },
  watch: {
    value (news, olds) {
      this.content = news
    }
  },
  methods: {
    getContent () {
      this.$emit('editorContent', this.content)
    },
    handleImageQuillSuccess (res) {
      const quill = this.$refs.quillEditorRef.quill
      if (res) {
        const length = quill.getSelection().index
        quill.insertEmbed(length, 'image', res.data.url)
        quill.setSelection(length + 1)
      } else {
        console.log('图片插入失败')
      }
    },
    handleMediaQuillSuccess (res) {
      if (res.code === 0) {
        const pointIndex = res.data.path.lastIndexOf('.')
        const fileType = res.data.path.substring(pointIndex + 1)
        if (fileType === 'mp3' || fileType === 'ogg') {
          this.insertAudio(res.data.url)
        } else {
          this.insertVideo(res.data.url)
        }
      } else {
        console.log('图片音频或视频失败')
      }
    },
    insertAudio (url) {
      const quill = this.$refs.quillEditorRef.quill
      const length = quill.getSelection().index
      quill.insertEmbed(length, 'audio', { url: url }, 'api')
      quill.setSelection(length + 1)
    },
    insertVideo (url) {
      const quill = this.$refs.quillEditorRef.quill
      const length = quill.getSelection().index
      quill.insertEmbed(length, 'video', url)
      quill.setSelection(length + 1)
    }
  }
}
</script>
<style>
.ql-editor {
  min-height: 300px;
  max-height: 600px;
}
.ql-snow {
  line-height: 20px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
  content: '12px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
  content: '14px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
  content: '16px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='18px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
  content: '18px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
  content: '20px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
  content: '24px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='28px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='28px']::before {
  content: '28px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='32px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='32px']::before {
  content: '32px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='36px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='36px']::before {
  content: '36px';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimSun]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimSun]::before {
  content: "宋体";
  font-family: "SimSun" !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimHei]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimHei]::before {
  content: "黑体";
  font-family: "SimHei";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Microsoft-YaHei]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Microsoft-YaHei]::before {
  content: "微软雅黑";
  font-family: "Microsoft YaHei";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=KaiTi]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=KaiTi]::before {
  content: "楷体";
  font-family: "KaiTi" !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=FangSong]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=FangSong]::before {
  content: "仿宋";
  font-family: "FangSong";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Arial]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Arial]::before {
  content: "Arial";
  font-family: "Arial";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Times New Roman"]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Times New Roman"]::before {
  content: "Times New Roman";
  font-family: "Times New Roman";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Sans-Serif]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Sans-Serif]::before {
  content: "Sans Serif";
  font-family: "Sans Serif";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: 'H 1';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: 'H 2';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: 'H 3';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: 'H 4';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: 'H 5';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: 'H 6';
}
.ql-snow .ql-editor a {
  text-decoration: none;
}
.ee-flag-source button {
  width: 38px !important;
}
</style>
