<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb path='内容管理' subPath='菜单列表'></bread-crumb>
    <!-- 主体列表 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="2">
          <el-button type="primary" @click="showAddDialog">添加菜单</el-button>
        </el-col>
        <el-col v-if="roleId==1" :span="4">
          <el-select v-model="param.admin_id" placeholder="请选择所属者" filterable @change="getMenuList">
            <el-option label="请选择所属者" :value="0"></el-option>
            <el-option v-for="item in adminList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
      </el-row>
    </el-card>
   <el-table :data="menuList" stripe border :cell-style="{ textAlign: 'center' }">
      <el-table-column prop="id" label="ID" align="center" width="60px"></el-table-column>
      <el-table-column prop="icon" label="图标" align="center">
        <template slot-scope="scope">
          <el-image v-if="scope.row.icon!=''" :src="scope.row.icon+'?imageView2/2/w/50'"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题" align="center"></el-table-column>
      <el-table-column label="类型" align="center">
        <template slot-scope="scope">
          {{scope.row.type | getTypeName}}
        </template>
      </el-table-column>
      <el-table-column label="位置" align="center">
        <template slot-scope="scope">
          {{scope.row.position | getPositionName}}
        </template>
      </el-table-column>
      <el-table-column label="内容" align="center">
        <template slot-scope="scope">
          <el-image v-if="scope.row.type==1 && scope.row.content!=''" :src="scope.row.content+'?imageView2/2/w/75'"></el-image>
          <div v-else>{{scope.row.content}}</div>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          {{scope.row.status | getStatusName}}
        </template>
      </el-table-column>
      <el-table-column v-if="roleId==1" prop="admin_name" label="所属" align="center"></el-table-column>
      <el-table-column prop="sort" label="排序值" align="center"></el-table-column>
      <el-table-column label="创建时间" align="center">
        <template slot-scope="scope">
          {{scope.row.create_time | dateTimeFormat}}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="290px">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteRow(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
   <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="param.page"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="param.count"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
   <menu-add :visible="addDialogAvaible"
      :id="currentId"
      @add="addMenu"
      @update="updateMenu"
      @closeDialog="closeAddDialog">
    </menu-add>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
import MenuAdd from './menuAdd.vue'
export default {
  name: 'menu_list',
  components: {
    'bread-crumb': BreadCrumb,
    'menu-add': MenuAdd
  },
  data () {
    return {
      menuList: [],
      adminList: [],
      roleId: 0,
      currentId: 0,
      total: 0,
      pageTotal: 1,
      param: {
        admin_id: 0,
        page: 1,
        count: 10
      },
      addDialogAvaible: false
    }
  },
  created () {
    this.getMenuList()
    this.getAdminList()
  },
  filters: {
    getTypeName (value) {
      if (value === 1) {
        return '弹出图片'
      } else {
        return '链接地址'
      }
    },
    getPositionName (value) {
      if (value === 2) {
        return '标题下面'
      } if (value === 3) {
        return '右边浮动'
      } if (value === 4) {
        return '顶部关注'
      } else {
        return '文章底部'
      }
    },
    getStatusName (value) {
      if (value === 2) {
        return '隐藏'
      } else {
        return '显示'
      }
    }
  },
  methods: {
    async getMenuList () {
      const res = await this.$http.get('/admin/menu/list', this.param)
      if (res.code === 0) {
        this.menuList = res.data.list
        this.total = res.data.total
        this.pageTotal = res.data.total_page
      } else {
        this.$message.error(res.msg)
      }
      this.roleId = parseInt(window.sessionStorage.getItem('role_id'))
    },
    async getAdminList () {
      const res = await this.$http.get('/admin/select_list')
      if (res.code === 0) {
        this.adminList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    search () {
      this.param.page = 1
      this.getMenuList()
    },
    handleSizeChange (size) {
      this.param.count = size
      this.getMenuList()
    },
    handleCurrentChange (page) {
      this.param.page = page
      this.getMenuList()
    },
    showAddDialog () {
      this.addDialogAvaible = true
    },
    closeAddDialog () {
      this.addDialogAvaible = false
      this.currentId = 0
    },
    showEditDialog (id) {
      this.currentId = id
      this.showAddDialog()
    },
    deleteRow (id) {
      this.$confirm('是否真的要删除此记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.post('/admin/menu/delete/' + id)
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.getMenuList()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {})
    },
    async addMenu (data) {
      const res = await this.$http.post('/admin/menu/add', data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getMenuList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async updateMenu (id, data) {
      const res = await this.$http.post('/admin/menu/update/' + id, data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getMenuList()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.list_img {
  width: 50px;
}
</style>
