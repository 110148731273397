<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb path='内容管理' subPath='评论列表'></bread-crumb>
    <!-- 主体列表 -->
    <el-card>
      <el-row :gutter="20">
       <el-col :span="4">
         <el-select v-model="param.status" placeholder="评论状态" filterable @change="getCommentList">
           <el-option label="请选择评论状态" :value="0"></el-option>
           <el-option label="未审核" :value="1"></el-option>
           <el-option label="审核通过" :value="2"></el-option>
           <el-option label="审核未通过" :value="3"></el-option>
         </el-select>
       </el-col>
      </el-row>
    </el-card>
   <el-table :data="commentList" stripe border :cell-style="{ textAlign: 'center' }">
      <el-table-column prop="id" label="ID" align="center" width="60px"></el-table-column>
      <el-table-column prop="obj_title" label="所属文章" align="center"></el-table-column>
      <el-table-column prop="content" label="评论内容" align="center"></el-table-column>
      <el-table-column prop="reply" label="回复" align="center"></el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          {{scope.row.status | getStatusName}}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template slot-scope="scope">
          {{scope.row.create_time | dateTimeFormat}}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="290px">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status === 1" type="success" icon="el-icon-edit" size="mini" @click="commentCheck(scope.row.id, 2)">通过</el-button>
          <el-button v-if="scope.row.status === 1" type="warning" icon="el-icon-edit" size="mini" @click="commentCheck(scope.row.id, 3)">不通过</el-button>
          <el-button v-if="scope.row.status === 2" type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">回复</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteRow(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
   <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="param.page"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="param.count"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <comment-reply
      :visible="addDialogAvaible"
      :id="currentId"
      @replyCommit="replyCommit"
      @closeDialog="closeAddDialog">
    </comment-reply>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
import CommentReply from './CommentReply.vue'
export default {
  name: 'comment_list',
  components: {
    'bread-crumb': BreadCrumb,
    'comment-reply': CommentReply
  },
  data () {
    return {
      categoryList: [],
      commentList: [],
      currentId: 0,
      total: 0,
      pageTotal: 1,
      param: {
        obj_id: 0,
        status: 0,
        page: 1,
        count: 10
      },
      addDialogAvaible: false
    }
  },
  created () {
    this.getCategoryList()
    this.getCommentList()
  },
  filters: {
    getStatusName (value) {
      if (value === 2) {
        return '审核通过'
      } else if (value === 3) {
        return '审核未通过'
      } else {
        return '未审核'
      }
    }
  },
  methods: {
    async getCategoryList () {
      const res = await this.$http.get('/admin/category/select_list')
      if (res.code === 0) {
        this.categoryList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async getCommentList () {
      const res = await this.$http.get('/admin/comment/list', this.param)
      if (res.code === 0) {
        this.commentList = res.data.list
        this.total = res.data.total
        this.pageTotal = res.data.total_page
      } else {
        this.$message.error(res.msg)
      }
    },
    async getContentList () {
      const res = await this.$http.get('/admin/select_list')
      if (res.code === 0) {
        this.adminList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async replyCommit (id, data) {
      const res = await this.$http.post('/admin/comment/reply/' + id, data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getCommentList()
      } else {
        this.$message.error(res.msg)
      }
    },
    search () {
      this.param.page = 1
      this.getCommentList()
    },
    handleSizeChange (size) {
      this.param.count = size
      this.getCommentList()
    },
    handleCurrentChange (page) {
      this.param.page = page
      this.getCommentList()
    },
    showAddDialog () {
      this.addDialogAvaible = true
    },
    closeAddDialog () {
      this.addDialogAvaible = false
      this.currentId = 0
    },
    showEditDialog (id) {
      this.currentId = id
      this.showAddDialog()
    },
    deleteRow (id) {
      this.$confirm('是否真的要删除此记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.post('/admin/comment/delete/' + id)
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.getCommentList()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {})
    },
    async commentCheck (id, value) {
      const res = await this.$http.post('/admin/comment/check/' + id, { status: value })
      if (res.code === 0) {
        this.$message.success('审核成功')
        this.getCommentList()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
</style>
