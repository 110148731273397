<template>
  <el-dialog :title="title" :visible.sync="addVisible" :before-close="closeDialog"  @closed="closeDialog" width="30%" @open="addOpen" :close-on-click-modal="false">
    <el-form :model="formData" :rules="addFormRules" ref="addFormRef" label-width="80px">
      <el-form-item label="用户名" prop="name">
        <el-input :disabled="id>0" v-model="formData.name"></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nick_name">
        <el-input v-model="formData.nick_name"></el-input>
      </el-form-item>
      <el-form-item v-if="id==0" label="密码" prop="password">
        <el-input v-model="formData.password" type="password"></el-input>
      </el-form-item>
      <el-form-item v-if="id==0" label="确认密码" prop="password_confirm">
        <el-input v-model="formData.password_confirm" type="password"></el-input>
      </el-form-item>
      <el-form-item label="头像" prop="avatar">
        <template>
          <el-upload
            class="avatar-uploader"
            action="/proxy/upload_img"
            name="img"
            :show-file-list="false"
            :on-success="handleSuccess">
            <img v-if="avatarUrl" :src="avatarUrl+'?imageView2/0/w/50'" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </template>
      </el-form-item>
      <el-form-item label="角色" prop="role_id">
        <el-select v-model="formData.role_id" placeholder="请选择角色" filterable>
          <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="域名" prop="domain">
        <el-input v-model="formData.domain"></el-input>
      </el-form-item>
      <el-form-item label="域名前缀" prop="domain_pre">
        <el-input v-model="formData.domain_pre"></el-input>
      </el-form-item>
      <el-form-item label="随机前缀" prop="pre_rand">
        <el-radio-group v-model="formData.pre_rand">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="0">不显示</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="底部菜单" prop="show_menu">
        <el-radio-group v-model="formData.show_menu">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="0">不显示</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="addConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'admin_add',
  props: [
    'addVisible', 'id', 'roleList'
  ],
  data () {
    return {
      title: '',
      formData: {
        name: '',
        nick_name: '',
        password: '',
        password_confirm: '',
        avatar: '',
        role_id: '',
        domain: '',
        domain_pre: '',
        pre_rand: 1,
        show_menu: 0
      },
      avatarUrl: '',
      addFormRules: {
        name: [
          { required: true, message: '用户名不能为空', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        nick_name: [
          { required: true, message: '昵称不能为空', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        role_id: [
          { required: true, message: '请选择性别', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.addFormRef.resetFields()
      this.avatarUrl = ''
      this.$emit('closeDialog')
    },
    addConfirm () {
      this.$refs.addFormRef.validate(valid => {
        if (!valid) return
        // 验证通过
        if (this.id === 0) {
          this.$emit('addAdmin', this.formData)
        } else {
          this.$emit('updateAdmin', this.id, this.formData)
        }
      })
    },
    addOpen () {
      // 如果id>0,则是编辑信息
      if (this.id > 0) {
        this.title = '编辑管理员'
        this.getAdminInfo()
      } else {
        this.title = '添加管理员'
      }
    },
    async getAdminInfo () {
      const res = await this.$http.get('/admin/info/' + this.id)
      if (res.code === 0) {
        this.formData = res.data
        this.avatarUrl = res.data.avatar_url
      } else {
        this.$message.error(res.msg)
      }
    },
    handleSuccess (resp, file, fileList) {
      if (resp.code === 0) {
        this.avatarUrl = resp.data.url
        this.formData.avatar = resp.data.path
      } else {
        this.$message.error(resp.msg)
      }
    }
  }
}
</script>

<style>
</style>
