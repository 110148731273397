<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <img class="logo" src="../assets/pray.jpg" />
        <span>1号文章后台管理系统</span>
      </div>
      <div>
 <!--       <span class="admin_name">{{info.nick_name}}</span>
        <el-button type="info" size="mini" @click="logout">退出</el-button> -->
        <el-dropdown>
          <span class="el-dropdown-link">
            {{info.nick_name}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item><span @click="openResetDialog">修改密码</span></el-dropdown-item>
            <el-dropdown-item><span @click="logout">退出</span></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <!-- 页面主体区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="menuCollapse? '64px' : '200px'">
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <home-menu :menuList="menuList"
          :collapse="menuCollapse"
          :activeIndex="menuActiveIndex"
          @changeActiveIndex="changeActiveIndex"></home-menu>
      </el-aside>
      <!-- 右侧内容主体 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>

    <password-reset
      :resetVisible="resetVisible"
      @closeResetDialog="closeResetDialog"
      @passwordReset="passwordResetPost">
    </password-reset>
  </el-container>
</template>

<script>
import HomeMenu from './HomeMenu.vue'
import PasswordReset from './PasswordReset.vue'

export default {
  name: 'home',
  components: {
    'home-menu': HomeMenu,
    'password-reset': PasswordReset
  },
  data () {
    return {
      info: {},
      menuList: [],
      menuCollapse: false,
      menuActiveIndex: '',
      resetVisible: false
    }
  },
  created () {
    this.getMenuList()
    this.getAdminInfo()

    this.menuActiveIndex = window.sessionStorage.getItem('menu_active_index')
  },
  methods: {
    async logout () {
      const res = await this.$http.post('/admin/logout')
      if (res.code === 0) {
        this.$message.success('退出成功')
        window.sessionStorage.clear()
        this.$router.push('/login')
      } else {
        this.$message.error(res.msg)
      }
    },
    async getAdminInfo () {
      const res = await this.$http.get('/admin/info')
      if (res.code !== 0) {
        this.$message.error(res.msg)
      }
      this.info = res.data
      window.sessionStorage.setItem('rights', res.data.rights)
      window.sessionStorage.setItem('role_id', res.data.role_id)
    },
    async getMenuList () {
      const res = await this.$http.get('/admin/right/menu/list')
      if (res.code !== 0) {
        this.$message.error(res.msg)
      }
      this.menuList = res.data
    },
    toggleCollapse () {
      this.menuCollapse = !this.menuCollapse
    },
    changeActiveIndex (index) {
      window.sessionStorage.setItem('menu_active_index', index)
      this.menuActiveIndex = index
    },
    openResetDialog () {
      this.resetVisible = true
    },
    closeResetDialog () {
      this.resetVisible = false
    },
    async passwordResetPost (data) {
      console.log(data)
      // 添加请求
      const res = await this.$http.post('/admin/update_password', data)
      if (res.code === 0) {
        this.$message.success('重置成功')
        this.logout()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.home-container {
  height: 100%;
}

.el-header {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  background-color: #CD6839;
  padding: 10px;
  box-sizing: border-box;
  color: #FFFFFF;
  font-size: 20px;
}
.el-header div {
  display: flex;
  align-items: center;
}
.el-header .logo {
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
}
.el-header .admin_name {
  color: #FFFFFF;
  font-size: 14px;
  margin-right: 10px;
}

.el-aside {
  background-color: #CD6839;
}

.el-main {
  background-color: #EAEDF1;
}

.toggle-button {
  background-color:#CD8162;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 2px;
  cursor: pointer;
}

.el-dropdown-link {
    cursor: pointer;
    color: #FFFFFF;
  }
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
