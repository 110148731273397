<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="closeDialog" @closed="closeDialog" width="25%" :close-on-click-modal="false">
    <el-form :model="formData" :rules="formRules" ref="formRef" label-width="60px">
      <el-form-item label="专辑" prop="category_id">
        <el-select v-model="formData.category_id" placeholder="请选择专辑" filterable>
          <el-option label="请选择专辑" value=""></el-option>
          <el-option v-for="item in categoryList" :key="item.id" :label="item.title" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="updateConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'category_update',
  props: [
    'visible', 'categoryList'
  ],
  data () {
    return {
      title: '批量更新所属专辑',
      formData: {
        category_id: ''
      },
      formRules: {
        category_id: [
          { required: true, message: '分类不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.formRef.resetFields()
      this.$emit('close')
    },
    updateConfirm () {
      this.$refs.formRef.validate(valid => {
        if (!valid) return
        // 验证通过
        this.$emit('update', this.formData.category_id)
      })
    }
  }
}
</script>

<style scoped>
</style>
