<template>
  <div>
    <vue-ueditor-wrap v-model="content" @input="getContent" :config="myConfig"></vue-ueditor-wrap>
  </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
  name: 'my_ueditor',
  components: {
    'vue-ueditor-wrap': VueUeditorWrap
  },
  props: [
    'value'
  ],
  data () {
    return {
      content: this.value,
      title: '中华人民共和国',
      myConfig: {
        autoHeightEnabled: false,
        serverUrl: '/proxy/upload',
        imageUrlPrefix: 'http://localhost:8080/',
        imageAllowFiles: ['.png', '.jpg', '.jpeg', ',gif'],
        imageMaxSize: 20480000,
        fileAllowFiles: ['.pdf', 'doc', 'docx', 'zip', 'rar'],
        initialFrameHeight: 580,
        initialFrameWidth: '100%',
        UEDITOR_HOME_URL: '/ueditor/'
      }
    }
  },
  watch: {
    value (news, olds) {
      this.content = news
    }
  },
  methods: {
    getContent () {
      this.$emit('getContent', this.content)
    }
  }
}
</script>

<style scoped>
</style>
